
/* exported MediaQuery */


var MediaQuery = (function() {

    'use strict';

    var config = {
            
            defaultQueries: {
                landscape: 'only screen and (orientation: landscape)',
                portrait: 'only screen and (orientation: portrait)',
            },
            
            dataElement: '.js-config-breakpoints',
        },
        
        queries = [],
        
        current = '',

        resizeEvent = $.fn.resizestop ? 'resizestop' : 'resize', // Use resizestop to improve performance


        init = function(_config) {
            // Extend module config
            $.extend(true, config, _config || {});
            
            // queries = config.defaultQueries;
            
            var namedQueries = unserializeMqData($(config.dataElement).css('font-family'));
            var allQueries = $.extend({}, config.defaultQueries, namedQueries);

            for (var key in allQueries) {
                if(allQueries.hasOwnProperty(key)) {
                    queries.push({
                        name: key,
                        value: 'only screen and (min-width: ' + allQueries[key] + ')'
                    });
                }
            }
            
            watchChanges();
        },
        
        unserializeMqData = function(str) {
            var styleObject = {};
            
            if (typeof str !== 'string') {
                return styleObject;
            }
            
            str = str.trim().slice(1, -1); // browsers re-quote string style values
            
            if (!str) {
                return styleObject;
            }
            
            styleObject = str.split('&').reduce(function(ret, param) {
                var parts = param.replace(/\+/g, ' ').split('=');
                var key = parts[0];
                var val = parts[1];
                key = decodeURIComponent(key);
            
                // missing `=` should be `null`:
                // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
                val = val === undefined ? null : decodeURIComponent(val);
            
                if (!ret.hasOwnProperty(key)) {
                    ret[key] = val;
                } else if (Array.isArray(ret[key])) {
                    ret[key].push(val);
                } else {
                    ret[key] = [ret[key], val];
                }
                return ret;
            }, {});
            
            return styleObject;
        },
        
        getQuery = function(size) {
            for (var i in queries) {
                if (queries.hasOwnProperty(i)) {
                    var query = queries[i];
                    if (size === query.name) {
                        return query.value;
                    }
                }
            }
        
            return null;
        },
        
        getCurrentSize = function() {
            var matched;

            for (var i = 0; i < queries.length; i++) {
                var query = queries[i];

                if (window.matchMedia(query.value).matches) {
                    matched = query;
                }
            }

            if (typeof matched === 'object') {
                return matched.name;
            } else {
                return matched;
            }
        },

        watchChanges = function() {
            $(window).on('resize.mediaquery', function() {
                var newSize = getCurrentSize(),
                    currentSize = current;
                    
                if (newSize !== currentSize) {
                    current = newSize;
                    $.publish('/mediaquery/change', newSize); // [newSize, currentSize]
                    $.publish('/mediaquery/change/' + newSize);
                }
            }).trigger('resize');
        },
        
        atLeast = function(size) {   
            var query = getQuery(size);
     
            if (query) {
                return window.matchMedia(query).matches;
            }
            
            return false;
        },
        
        is = function(size) {
            size = size.trim().split(' ');
            
            if(size.length > 1 && size[1] === 'only') {
                if(size[0] === getCurrentSize()) {
                    return true;
                }
            }
            else {
                return atLeast(size[0]);
            }
            
            return false;
        };



    // Public properties and methods
    return {
        init: init,
        atLeast: atLeast,
        is: is
    };

})();


