/* globals $, ga, appConfig, pageData, MediaQuery, Modernizr, objectFitImages */

/* exported App */

'use strict';

var App = (function() {

    var config = {
        selectors: {
            body: 'body',

            navigationToggle: '.js-navigation-toggle',
            navigationLink: '.js-navigation a',

            anchorLink: 'a[href^="#"]',

            smoothScrolling: '.js-smooth-scroll',

            transitionConfig: '.js-config-container-transition',

            cookieAccept: '.js-cookie-accept',

            stickyHeader: '.js-sticky-header',
            pageHeader: '.js-page-header',

            scrollContainer: '.js-scroll-container',
            scrollSection: '.js-scroll-section',

            contentContainerTrigger: '[data-content-container]',
            contentContainerTriggerExclude: '[data-content-container-exclude]',
            subContainer: '.js-sub-container',
            subContainerTrigger: '[data-sub-container]',
            prevSubContainer: '.js-prev-sub-container',
            nextSubContainer: '.js-next-sub-container',

            collapsableContents: '.content-style--collapse-tables',

            projectPreview: '.js-project-preview',
            projectDetail: '#js-project-detail',
            projectDetailClose: '.js-project-detail-close',

            announcement: '.js-announcement',
            announcementClose: '.js-announcement-close',

            globalAnnouncement: '.js-global-announcement',
            globalAnnouncementClose: '.js-global-announcement-close',

            slideshow: '.js-slideshow',
            slideshowOuter: '.js-slideshow-outer',

            legalClose: '.js-legal-close',

        },

        scrollAnchorPrefix: 'anchor__',

        smoothScrollingSpeed: 600,
        objectFitInterval: 3000,

        // Breakpoint when scrolling makes sense

        breakpointNavAlwaysVisible: 'medium',

        // Idle screen

        idleScreenEnabled: window.appConfig.splash.showOnIdle,
        idleScreenTimeout: 120000, // when to set user as idle
        idleScreenDelay: window.appConfig.splash.minDuration || 3000, // when to start counting
        idleResetEvents: 'mousemove mousedown keydown touchstart scroll mousewheel',

        // Dynamic containers

        dynamicContainers: true,
        dynamicContainerTransition: 100,

        // Slideshows: optional (disabled) items are prefixed by a _

        slideshowSettings: {
            _autoplay: {
                delay: 8000,
            },
            speed: 600,
            loop: true,
            spaceBetween: 15,
            direction: 'horizontal',
            grabCursor: true,
            mousewheelForceToAxis: true,
            autoHeight: false,
            navigation: {
                prevEl: '.js-slideshow-prev',
                nextEl: '.js-slideshow-next',
            },
        },
    },

    selectors = {},

    elements = {}, $e,



    init = function(_config) {
        // Extend module config
        $.extend(true, config, _config || {});

        // Cache all elements for later use
        cacheElements();

        // DOM and event bindings
        setupBindings();

        // Pub/sub
        setupEvents();

        // Object-fit images
        objectFitPolyfill();

        setupContentContainers();
        setupSubContainers();
        setupPageRouting();

        setupProjectPreviews();
        setupLegalOverlay();
        setupIdleScreen();
        setupAnnouncements();
        setupGlobalAnnouncements();
        setupScrollSections();
        cookieNotice();

        // Scroll to URL anchor / hash
        scrollToHash();
    },



    cacheElements = function() {
        // Iterate over all selectors in config and store them as jQuery objects
        $.each(config.selectors, function(elementName, selector) {
            elements[elementName] = $(selector);
        });

        // Shortcuts
        selectors = config.selectors;
        $e = elements;
    },


    // DOM bindings
    setupBindings = function() {

        // Toggle mobile navigation
        $(document).on('click', selectors.navigationToggle, function(event){
        	$e.body.toggleClass('is-nav-visible');
        	event.preventDefault();
        });

        // Show navigation by default
        if (pageHasTemplate('home') && !location.hash && !appConfig.local) {
            $e.navigationToggle.click();
        }

    	// Make anchor links scroll smoothly
    	$(document).on('click', selectors.anchorLink, function(){
            if (!isPageRoutingEnabled()) return;
            return scrollToLink($(this));
        });

        // Scroll all scroll containers to top
        $e.scrollContainer.scrollTop(0);

        // Resizing? Save to body
        $(window).on('resizestart',   0, function(){ $e.body.addClass('is-resizing'); });
        $(window).on('resizestop', 1000, function(){ $e.body.removeClass('is-resizing'); });

        // Breakpoint change
        $.subscribe('/mediaquery/change', function(_, size) {
            if (MediaQuery.atLeast('small')) {

            }
        });

    	// Re-initialize page after ajax page loads
    	$(window).on('statechangecomplete', onStateChange);

        // "Fake" state change to initialize everything now
    	onStateChange();
    },

    // Observers for interaction with other modules
    setupEvents = function() {
        // Show and hide loading spinner when loading over Ajax
        $.subscribe('/ajax/start', function() { $e.body.addClass('is-loading'); });
        $.subscribe('/ajax/stop',  function() { $e.body.removeClass('is-loading'); });

        // Log all ajax errors to console
        $(document).ajaxError(function(event, jqXHR, settings, error) {
            console.error('Ajax request failed: ' + error + ' ('+settings.url+')');
        });
    },

    onStateChange = function() {
        openExternalLinksInNewTab();
        setupCollapsableContents();
        setupSlideshows();
    },

    pageHasTemplate = function(template) {
        return $e.body.hasClass('template-' + template);
    },

    scrollToLink = function($link) {
        var anchor = $link.attr('href').replace('#', ''),
            container = $link.data('show-container'),
            subContainer = $link.data('show-sub-container'),
            goToAnchor = $link.data('anchor'),
            title = $link.text();

        if (canScrollToAnchor(anchor) && container) {
            setCurrentContentContainer(container);
            if (subContainer) {
                setSubContainer(subContainer);
            }

            if (!MediaQuery.atLeast(config.breakpointNavAlwaysVisible)) {
                if (goToAnchor) {
                    scrollToAnchor(goToAnchor);
                }
                else {
                    $('html, body').animate({
                        scrollTop: 0
                    }, config.smoothScrollingSpeed);
                }

                $e.pageHeader.html(title);
                $e.body.removeClass('is-nav-visible');
                return false;
            }
        }

        return scrollToAnchor(anchor);
    },

    getAnchorToScrollTo = function(anchor) {
        var $scrollToAnchor = $('#' + config.scrollAnchorPrefix + anchor),
            $scrollToDirect = $('#' + anchor),
            $scrollTo = $scrollToAnchor.length ? $scrollToAnchor : $scrollToDirect;

        return $scrollTo;
    },

    canScrollToAnchor = function(anchor) {
        var $scrollTo = getAnchorToScrollTo(anchor);
        return !!$scrollTo.length;
    },

    scrollToAnchor = function(anchor) {
        var $scrollTo = getAnchorToScrollTo(anchor);
        if ($scrollTo.length) {
            var $parent = $scrollTo.scrollParent();
            $parent = $parent.length ? $parent : $('html, body');

            $parent.animate({
                scrollTop: Math.max(0, $scrollTo.offset().top - 50)
            }, config.smoothScrollingSpeed);
            $e.body.removeClass('is-nav-visible');
            return false;
        }
        else {
            console.warn('Anchor not found', anchor);
        }
    },

    scrollToHash = function() {
        hash = location.hash;
        if (hash) {
            scrollToAnchor(hash.replace('#', ''));
        }
    },



    trackCustomEvent = function(category, action, label){
        ga('send', 'event', category, action, label);
    },

    objectFitPolyfill = function () {
        if (Modernizr.objectfit) {
            return;
        }

        if (typeof objectFitImages !== 'function') {
            return;
        }

        var triggerObjectFit = function () {
            objectFitImages(null, {watchMQ: true});
        };

        triggerObjectFit();
        setTimeout(triggerObjectFit, config.objectFitInterval / 2);
        setInterval(triggerObjectFit, config.objectFitInterval);
    },

    openExternalLinksInNewTab = function () {
        $('a')
            .filter('[href^="http"], [href^="//"]')
            .not('[href*="' + window.location.host + '"]')
            .attr('rel', 'noopener noreferrer')
            .attr('target', '_blank');
    },


    loadCSSConfig = function ($element) {
        var configStr = $element.css('font-family');
        return configStr.trim().slice(1, -1); // browsers re-quote string style values
    },

    loadTransitionConfig = function () {
        var duration = parseInt(loadCSSConfig($e.transitionConfig));
        if (duration && duration > 0) {
            config.dynamicContainerTransition = duration;
        }
    },

    cookieNotice = function () {
        var cookieName = 'hidecookienotice';

        $e.body.toggleClass('is-cookie-notice-visible', !Cookies.get(cookieName));
        $e.cookieAccept.click(function () {
            Cookies.set(cookieName, 1, { expires: 7 });
            $e.body.removeClass('is-cookie-notice-visible');
        });
    },

    setIdle = function () {
        $e.body.addClass('is-user-idle');
    },

    unsetIdle = function () {
        $e.body.removeClass('is-user-idle');
    },

    setupIdleScreen = function () {
        if (!config.idleScreenEnabled) {
            return;
        }

        setTimeout(function(){
            $(document).idle({
                onIdle:   setIdle,
                onActive: unsetIdle,
                onHide:   setIdle,
                onShow:   unsetIdle,
                events:   config.idleResetEvents,
                idle:     config.idleScreenTimeout
            });
        }, config.idleScreenDelay);
    },

    setupScrollSections = function () {
        $e.scrollContainer.each(function(){
            var $container = $(this),
                $section = $container.closest(selectors.scrollSection),
                scrollTop = $container.scrollTop();
                $container.scroll(_.throttle(function(){
                    scrollTop = $container.scrollTop();
                    $section.toggleClass('is-section-scrolled', scrollTop > 10);
                }, 50));
        });
    },

    isPageRoutingEnabled = function() {
        return window.isPageRoutingEnabled && MediaQuery.atLeast(config.breakpointNavAlwaysVisible);
    },

    setupPageRouting = function () {
        if (!window.pageContainers) return;

        page(window.projectListUrl + ':slug', function (ctx, next) {
            // if (!isPageRoutingEnabled()) return next();
            console.log('Project page: ', ctx.params.slug, ctx.pathname);
            openProjectDetail(ctx.pathname);
        });

        page('*', function (ctx, next) {
            closeProjectDetail();
            $e.body.removeClass('is-nav-visible');
            scrollToHash();
            next();
        });

        $.each(window.pageContainers, function (url, container) {
            page(url, function (ctx, next) {
                if (!isPageRoutingEnabled()) return next();
                console.log('Main container page: ', container);
                openContentContainer(container, ctx);
            });
        });

        $.each(window.packageUrls, function (url, subcontainer) {
            page(url, function (ctx, next) {
                if (!isPageRoutingEnabled()) return next();
                console.log('Sub container page: ', subcontainer);
                openSubContainer(subcontainer);
            });
        });

        page.start({
            window: window,
            dispatch: true,
        });
    },

    openContentContainer = function (containerID, context) {
        if (isCurrentContentContainer(containerID)) {
            return;
        }
        if (!MediaQuery.atLeast(config.breakpointNavAlwaysVisible)) {
            return;
        }
        if (context && context.hash) {
            setTimeout(function () {
                scrollToAnchor(context.hash);
            }, config.dynamicContainerTransition || 250);
        }
        setCurrentContentContainer(containerID);
        setPreviewContentContainer(null);
        markTransition();
    },

    openSubContainer = function (containerID) {
        if (containerID) {
            openContentContainer('package-list');
            setSubContainer(containerID);
        }
    },

    previewContentContainer = function (containerID) {
        if (containerID && $e.body.hasClass('is-resizing')) {
            return;
        }
        if (!MediaQuery.atLeast(config.breakpointNavAlwaysVisible)) {
            return;
        }
        setPreviewContentContainer(containerID);
    },

    setupContentContainers = function () {
        if (!config.dynamicContainers) {
            return;
        }

        loadTransitionConfig();

        $e.contentContainerTriggerExclude.click(function (event){
            event.stopPropagation();
        });

        $e.contentContainerTrigger.click(function (event){
            var $container = $(this),
                containerID = $container.data('content-container'),
                containerURL = $container.data('url');

            if (!isPageRoutingEnabled()) return;

            if ($container.is('a')) {
                event.preventDefault();
            }

            if (containerURL) {
                page.show(containerURL);
            }
            else {
                openContentContainer(containerID);
            }
            console.log('Main click', containerID, containerURL);
        });

        $e.contentContainerTrigger.hover(
            function() {
                previewContentContainer($(this).data('content-container'));
            },
            function(){
                previewContentContainer(null);
            }
        );
    },

    setupSubContainers = function () {
        if (!config.dynamicContainers) {
            return;
        }

        // setSubContainer(2);

        $e.subContainerTrigger.click(function (event) {
            var $trigger = $(this),
                $container = $trigger.closest(selectors.subContainer),
                containerID = $trigger.data('sub-container'),
                containerURL = $trigger.data('url');

            if (containerURL) {
                page.show(containerURL);
            } else {
                openSubContainer(containerID);
            }
            event.preventDefault();
            event.stopPropagation();
        });

        $e.prevSubContainer.click(function(event){
            if (isCurrentSubContainer(2)) {
                $e.subContainerTrigger.eq(0).click(); // 1
            }
            else if (isCurrentSubContainer(3)) {
                $e.subContainerTrigger.eq(1).click(); // 2
            }
            event.preventDefault();
            event.stopPropagation();
        });
        $e.nextSubContainer.click(function (event) {
            if (isCurrentSubContainer(1)) {
                $e.subContainerTrigger.eq(1).click(); // 2
            }
            else if (isCurrentSubContainer(2)) {
                $e.subContainerTrigger.eq(2).click(); // 3
            }
            event.preventDefault();
            event.stopPropagation();
        });
    },

    markTransition = function(){
        $e.body.addClass('is-content-transitioning');
        $e.body.removeClass('is-content-not-transitioning');
        setTimeout(function(){
            $e.body.removeClass('is-content-transitioning');
            $e.body.addClass('is-content-not-transitioning');
        }, config.dynamicContainerTransition);
    },

    isCurrentContentContainer = function (container, state){
        state = state || 'visible';
        return $e.body.hasClass(state + '-container-' + container);
    },

    setCurrentContentContainer = function (container, state) {
        state = state || 'visible';

        // Scroll content up after switching containers
        if ($.inArray(state, ['visible', 'sub']) > -1 &&  !isCurrentContentContainer(container, state)) {
            setTimeout(function(){
                $e.scrollContainer.scrollTop(0);
                $e.body.removeClass('is-project-detail-visible');
            }, config.dynamicContainerTransition);
        }

        // Remove old classnames
        var classPattern = new RegExp('(^|\\s)' + state + '-container-\\S+', 'g');
        $e.body.removeClass(function (index, className) {
            return (className.match(classPattern) || []).join(' ');
        });

        // Add new classname
        if (container) {
            $e.body.addClass(state + '-container-' + container);
        }
    },

    setPreviewContentContainer = function (container) {
        setCurrentContentContainer(container, 'previewed');
    },

    setSubContainer = function (container) {
        setCurrentContentContainer(container, 'sub');
    },

    isCurrentSubContainer = function (container) {
        return isCurrentContentContainer(container, 'sub');
    },

    setupCollapsableContents = function () {
        var $newContent = $(selectors.collapsableContents).filter(function() {
            return $(this).data('initialised') !== true;
        });
        $newContent.each(function () {
            $('table', this).each(function(){
                var $table = $(this),
                    $header = $('thead', this),
                    $body = $('tbody', this);
                if ($header.length && $body.length) {
                    $table.addClass('is-collapsible');
                    $body.slideUp(0);
                    $header.click(function(){
                        $body.slideToggle();
                    });
                }
            });
        }).data('initialised', true);
    },

    setupLegalOverlay = function () {
        $e.legalClose.click(function () {
            setCurrentContentContainer();
        });
    },

    setupProjectPreviews = function () {
        $e.projectDetailClose.click(closeProjectDetail);
    },

    openProjectDetail = function (projectURL) {
        openContentContainer('about');
        $e.body.addClass('is-project-detail-loading');
        loadProject(projectURL);
    },

    closeProjectDetail = function () {
        $e.body.removeClass('is-project-detail-loading');
        $e.body.removeClass('is-project-detail-visible');
    },

    loadProject = function (projectURL) {
        $.publish('/ajax/start');

        $.get(projectURL, function(data){
            var $container = $(selectors.projectDetail),          // in current page's HTML
                $results   = $(selectors.projectDetail, $(data)); // in returned Ajax HTML

            if ($container.length && $results.length) {
                $container.html($results.html()).scrollTop(0);
                $e.body.addClass('is-project-detail-visible');
                onStateChange();
            } else {
                window.location.replace(projectURL);
            }
        }).fail(function() {
            // window.location.replace(projectURL);
        }).always(function() {
            $.publish('/ajax/stop');
            $e.body.removeClass('is-project-detail-loading');
        });
    },

    setupSlideshows = function () {
        var $newSlideshows = $(selectors.slideshow).filter(function() {
            return $(this).data('setup') !== true;
        });

        $newSlideshows.each(function(){
            var slideshowConfig = $.extend(true, {}, config.slideshowSettings),
                $slideshow = $(this),
                $outer = $slideshow.closest(selectors.slideshowOuter);

            // Look for navigation buttons in outer wrapper
            if ($outer.length) {
                slideshowConfig.navigation.prevEl = $outer.find(slideshowConfig.navigation.prevEl);
                slideshowConfig.navigation.nextEl = $outer.find(slideshowConfig.navigation.nextEl);
            }

            // Autoplay set?
            if ($slideshow.is('[data-autoplay]')) {
                slideshowConfig.autoplay = slideshowConfig._autoplay;
            }

            var swiperInstance = new Swiper(this, slideshowConfig);
        })
        .data('setup', true);
    },

    setupGlobalAnnouncements = function () {
        if (!$e.globalAnnouncement.length) return;
        if (!pageHasTemplate('home')) return;

        var pageId = $e.globalAnnouncement.data('page');
        var cookieName = 'hideannouncement' + pageId;
        $e.body.toggleClass('is-global-announcement-visible', !Cookies.get(cookieName));

        $e.globalAnnouncement.click(function (event) {
            if (event.target === this) {
                Cookies.set(cookieName, 1, { expires: 7 });
                $e.body.removeClass('is-global-announcement-visible');
            }
        });
        $e.globalAnnouncementClose.click(function () {
            Cookies.set(cookieName, 1, { expires: 7 });
            $e.body.removeClass('is-global-announcement-visible');
        });
    },

    setupAnnouncements = function () {
        $e.announcementClose.click(function(){
            var $container = $(this).closest(selectors.announcement);
            $container.animate({
                height: 0,
                opacity: 0,
                margin: 0,
                padding: 0
            }, 'slow', function(){
                $(this).hide();
            });
        });
    };

    // Public properties and methods
    return {
        init: init,
    };

})();

// General site/app module

$(function() {

    // Initialize media query detection

    MediaQuery.init();

    // Load up app and initialize

    App.init();

});
